import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Icon, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaSmileBeam, FaRegCalendarCheck } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Clay Court Start!
			</title>
			<meta name={"description"} content={"Відчуйте радість тенісу на наших глиняних кортах преміум-класу"} />
			<meta property={"og:title"} content={"Головна | Clay Court Start!"} />
			<meta property={"og:description"} content={"Відчуйте радість тенісу на наших глиняних кортах преміум-класу"} />
			<meta property={"og:image"} content={"https://top.tovirexstart.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://top.tovirexstart.com/img/images.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://top.tovirexstart.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://top.tovirexstart.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://top.tovirexstart.com/img/images.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://top.tovirexstart.com/img/images.png"} />
			<meta name={"msapplication-TileImage"} content={"https://top.tovirexstart.com/img/images.png"} />
			<meta name={"msapplication-TileColor"} content={"https://top.tovirexstart.com/img/images.png"} />
		</Helmet>
		<Components.Header />
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
			<Text
				margin="0px 0px 10px 0px"
				color="--dark"
				lg-text-align="center"
				font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
			>
				Clay Court 
			</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="normal 700 54px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Start!
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
				Ласкаво просимо до Clay Court Start, де ваші тенісні мрії набувають форми у вигляді доглянутих глиняних кортів. Наш комплекс створений для того, щоб забезпечити винятковий досвід гри в теніс для гравців усіх рівнів. Якщо ви хочете забронювати корт для товариського матчу, орендувати високоякісне обладнання або вдосконалити свої навички за допомогою індивідуальних тренувань, у нас є все, що вам потрібно. Відкрийте для себе радість тенісу разом з нами, де кожна гра - це крок до досконалості.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contacts"
						padding="12px 24px 12px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-primary"
						hover-background="--color-primary"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Запланувати візит
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://top.tovirexstart.com/img/main.jpg) center center/cover no-repeat scroll padding-box"
				min-height="200px"
			/>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="24px 4%" md-grid-template-columns="1fr">
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="bs"
						icon={BsFillPeopleFill}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Про нас
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Clay Court Start - це більше, ніж просто місце для гри в теніс, це спільнота, де процвітає любов до гри. Наш комплекс може похвалитися найсучаснішими ґрунтовими кортами, спроектованими для того, щоб запропонувати найкращий ігровий досвід. Орієнтуючись на якість, доступність та пристрасть до тенісу, ми надаємо все необхідне для того, щоб ви могли насолоджуватися грою в повній мірі. Від оренди спорядження до професійних тренувань, наша мета - зробити теніс доступним і приємним для кожного. Приходьте і відчуйте різницю в Clay Court Start.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaSmileBeam}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Чисті глиняні корти
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Відчуйте унікальне відчуття гри на глиняних кортах, що відповідають професійним стандартам.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaRegCalendarCheck}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Оренда спорядження
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Високоякісне тенісне спорядження для будь-якого віку та рівня майстерності, що гарантує вашу найкращу гру.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaRegCalendarCheck}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Досвідчені тренери
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Вдосконалюйте свої навички з нашими досвідченими тренерами, пропонуючи індивідуальні тренування, адаптовані до ваших потреб.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaRegCalendarCheck}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Гнучке бронювання
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Просте та зручне бронювання корту, що дозволяє вам грати тоді, коли вам зручно.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-5">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 36px 00px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="30%"
					lg-width="100%"
					lg-margin="24px 0px 30px 0px"
					lg-order="1"
					md-text-align="left"
				>
					Знайдіть нас у самому центрі вашого міста, де ми завжди раді вітати як новачків, так і досвідчених гравців. Для бронювання кортів, оренди спорядження або тренувань - звертайтеся до нас.
				</Text>
				<Box
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 25% 0px"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
					lg-padding="0px 0px 50% 0px"
					lg-width="100%"
					width="55%"
					height="auto"
				>
					<Image
						src="https://top.tovirexstart.com/img/2.jpg"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						left={0}
					/>
				</Box>
			</Box>
			<Box
				justify-content="center"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="45%"
				lg-width="100%"
				align-items="flex-start"
			>
				<Box
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					overflow-y="hidden"
					position="relative"
					padding="0px 0px 150% 0px"
					lg-padding="0px 0px 100% 0px"
				>
					<Image
						object-fit="cover"
						display="block"
						top={0}
						lg-right="0px"
						lg-width="100%"
						bottom={0}
						min-height="100%"
						lg-left="-1px"
						src="https://top.tovirexstart.com/img/3.jpg"
						position="absolute"
						width="100%"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="55%"
				lg-order="1"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 40% 0px"
				>
					<Image
						src="https://top.tovirexstart.com/img/4.jpg"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
					/>
				</Box>
				<Text
					lg-text-align="center"
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					md-text-align="left"
				>
					Clay Court Start!: Де кожна подача приносить радість. Забронюйте свій майданчик сьогодні!
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});